import * as React from "react";

// Hero Media

export const HeroMedia = () => {
  return (
    <>
      <img 
        className="media bkg d-none d-sm-block" 
        src="/images/resources/4.0_HIV_resources_hero.png" 
        alt="Group of men at cookout laughing together" 
      />
      <img 
        className="media bkg d-block d-sm-none" 
        src="/images/resources/4.0_HIV_resources_hero-mobile.png" 
        alt="Group of men at cookout laughing together"  
      />
    </>
  )
}

// Hero Call To Action

export const Cta = (props) => {
  return (
    <>
      <h1>
        Resources for You
      </h1>
      <p>
        No matter your HIV test results, know that you’re not alone. There are 
        tools, information, and support to help you with every step of your journey.  
      </p>
      <div 
        onClick={() => {props.scrollToElement("nav-anchor-resources",-70,-370)}}
        className="scroll" role="button">
        <p className="copy">
          SCROLL
        </p>
        <img className="icon-scroll" src="/images/global/play-icon.svg" />
      </div>
    </>
  )
} 
