import * as React from 'react';

const Article1 = {
  className: "taking-the-first-step",
  imgSrc: "/images/resources/moment_of_testing_brochure.png",
  imgAlt: "Now that I know my HIV status, I’m moving forward with clarity",
  title: "Taking the First Step Brochure",
  copy: "Get the facts about HIV, the testing process, and what to do after you get your results.",
  linkCopy: "DOWNLOAD NOW",
  linkUrl: "/pdf/HIV-Franchise-Press-Play-Testing-Brochure.pdf",
  download: true,
  interstitial: false,
  dataInterstitialType: '',
}

const Article2 = {
  className: "aids-vu",
  imgSrc: "/images/resources/AIDSVu_logo.png",
  imgAlt: "AIDSVu logo",
  title: "HIV Services in Your Area",
  copy: "From testing sites to healthcare professionals to support groups, you can find the support and care you need. ",
  linkCopy: "SEARCH YOUR AREA",
  linkUrl: "https://aidsvu.org/services/#/testing",
  interstitial: true,
  dataInterstitialType: "aidsvu",

}

const Article3 = {
  className: "healthy-sexual",
  imgSrc: "/images/resources/Healthysexual_logo.png",
  imgAlt: "Healthysexual logo",
  title: "Healthy Sexual",
  copy: "Learn how to take a proactive approach to protecting your sexual health and understanding your HIV prevention options.",
  linkCopy: "VISIT TODAY",
  linkUrl: "https://www.healthysexuals.com",
  interstitial: false,
}

const Article4 = {
  className: "stop-the-virus",
  imgSrc: "/images/resources/Stop_The_Virus_logo.png",
  imgAlt: "Help Stop The Virus logo",
  title: "Help Stop the Virus",
  copy: "Living with HIV is different for everyone. Learn about HIV, how HIV treatment works, and your treatment options. HIV does not have to stop you from living a longer and healthier life.",
  linkCopy: "START HERE",
  linkUrl: "https://www.helpstopthevirus.com/hiv-treatment",
  interstitial: false,
}

const Article = (content) => {
  return (
    <div className={`col-12 col-lg-6 article ${content.className}`}>
      <img src={content.imgSrc} alt={content.imgAlt} />
      <h3>
        {content.title}
      </h3>
      <p>
        {content.copy}
      </p>
      <a 
        className={`${content.interstitial ? 'opens-interstitial' : ''}`} 
         data-interstitial-type={content.dataInterstitialType}
         target="_blank"
         href={content.linkUrl} 
         download={content.download}>
        {content.linkCopy}
      </a>
    </div>
  )

}

const Articles = () => {
  return (
    <div className="container px-0">
      <div className="row articles">
        {Article(Article1)}
        {Article(Article2)}
        {Article(Article3)}
        {Article(Article4)}
      </div>
    </div>
  )
}

export default Articles;