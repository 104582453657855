import * as React from "react";
import { useState } from 'react';
import { useEffect } from 'react';
import setInterstitialText from "./SetInterstitialText.js";

const FooterCalloutBar = (props) => {

  const [input, setInput] = useState(props?.value ?? '');
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [errorType, setErrorType] = useState('empty');
  const [focused, setFocused] = React.useState(false)

  const onFocus = () => {
    setFocused(true)
  }

  const onBlur = () => {
    setFocused(false)
  }

  useEffect(() => {
    const exp = /^[0-9]{5}$/;
    if (input.length === 0) setErrorType('empty');
    else if (!exp.test(input)) setErrorType('invalid');
    else (setErrorType('valid'));
  }, [input]);

  useEffect(() => {
    console.log('errorType', errorType);

  }, [errorType])

  const returnErrorMessage = (errorType) => {

    switch (errorType) {
      case 'empty':
        return 'Please enter a 5-digit U.S. ZIP code';
      case 'invalid':
        return 'Invalid. Please enter a 5-digit U.S. ZIP code';
      default:
        return '';
    }
  }

  const submit = () => {
    setSubmissionAttempted(true);
    if (errorType === 'valid') {
      setInterstitialText("aidsvu");
      const interstitial = document.querySelector('.interstitial-container');
      const body = document.querySelector('body');
      interstitial.classList.remove('interstitial-hidden');
      body.classList.add('hide-scroll');
      let interstitialContinue = interstitial.querySelector('.interstitial-link.continue');
      const url = `https://aidsvu.org/services/#/testing?zip=${input}`;
      interstitialContinue.href = url;
    }
  }

  return (
    <div className="container-fluid footer-callout-bar">
      <div className="row">
        <div className="col search">
          <div
            className={
              `input-container
                ${submissionAttempted ? 'submission-attempted' : ''} 
                ${errorType}`
            }>
            <h3>
              Find an HIV Testing Site <br className="d-block d-md-none" />Near You
            </h3>
            <input
              id="zip-code-field"
              type="number"
              value={input}
              onInput={e => {
                setInput(e.target.value)
              }
              }
              onFocus={onFocus}
              onBlur={onBlur}
            />
            {/*<label htmlFor="zip-code-field"></label>*/}
            <div className="below-input">
              <img className={`${focused ? '' : 'show'} yield-sign white`} src="../images/resources/yield-sign-white.svg" alt="yield sign white" />
              <img className={`${focused ? 'show' : ''} yield-sign red `} src="../images/resources/yield-sign-red.svg" alt="yield sign red" />
              <p className="zip-code">
                ZIP Code
              </p>
              <p className="error-message">
                {returnErrorMessage(errorType)}
              </p>
            </div>
            <button className="search open-interstitial-on-valid" onClick={submit}>
              SEARCH
            </button>
          </div>
        </div>
        <div className="col tests">
          <div>
            <h3>
              Request Free At-home Tests*
            </h3>
            <a
              id="anchor-test-now"
              data-interstitial-type="gilead"
              className="opens-interstitial"
              href="https://together.takemehome.org/?utm_source=Gilead&utm_medium=Partner-Organic-English&utm_campaign=selftesting12_PRIORITY&utm_term=BHOC-no-campaign-term&utm_content=Partner-promotion"
            >
              START HERE
            </a>
            <p className="footnote">
              *Eligibility requirements may apply.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterCalloutBar
