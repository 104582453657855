import React, { useEffect } from 'react';

export const Interstitial = (props) => {

  useEffect(() => {
    let interstitial = document.querySelector('.interstitial-container');
    let interstitialClose = document.querySelector('.interstitial-link.close');
    let interstitialContinue = document.querySelector('.interstitial-link.continue');
    const body = document.querySelector('body');
    interstitialClose.addEventListener('click', () => {
      interstitial.classList.add('interstitial-hidden');
      body.classList.remove('hide-scroll');
    });
    interstitialContinue.addEventListener('click', () => {
      interstitial.classList.add('interstitial-hidden');
      body.classList.remove('hide-scroll');
    });
  }, [])

  return (

    <div className="interstitial-container generic interstitial-hidden">
      <div className="interstitial-content">
        <h1 className='interstitial-header'>YOU ARE ABOUT <br className="d-block d-lg-none" />TO LEAVE OUR SITE</h1>
        <p className='interstitial-subtext'>
          By following this link, you're on your way to knowing your status. Just keep in
          mind, you should talk to a healthcare professional about all
          your options and what's right for you.
          <br /><br />
          AIDSVu is presented by the Rollins School of Public Health at Emory University
          in partnership with Gilead. Gilead provides this as a convenience.
        </p>
        <div className="interstitial-links">
          <a className="interstitial-link continue" href="" target="_blank">CONTINUE</a>
          <a className="interstitial-link close bdr-thick-mobile">GO BACK</a>
        </div>
      </div>
    </div>

  )


}

{/*<div className="interstitial-container generic interstitial-hidden">
            <div className="interstitial-content">
                <h1 className='interstitial-header'>YOU ARE ABOUT <br className="d-block d-lg-none"/>TO LEAVE OUR SITE</h1>
                <p className='interstitial-subtext'>
                  By following this link, you are leaving our site. AIDSVu is 
                  presented by the Rollins School of Public Health at Emory University 
                  in partnership with Gilead. Gilead provides this link as a convenience. For 
                  medical advice, please contact your healthcare provider.
                </p>
                <div className="interstitial-links">
                    <a className="interstitial-link continue" href="" target="_blank">CONTINUE</a>
                    <a className="interstitial-link close bdr-thick-mobile">GO BACK</a>
                </div>
            </div>
        </div>
    */}