import * as React from "react";
import Bar from '../../components/global/Bar.js'

const Hero = (props) => {
  return (
    <div className={` hero ${props.page === 'home' ? 'home' : 'not-home'} `}>
      <div className="container content px-sm-4 px-md-0">
        <Bar classes="purple" page={props.page} />
        <div className="overlay">
          {props.heroCopy && props.heroCopy}
          <div className={`cta ${props.page}`}>
            {props.CTA}
          </div>
        </div>
      </div>
      {props.heroMedia}
    </div>
  )
}

export default Hero
