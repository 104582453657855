import * as React from "react"
import '../assets/sass/app.scss';
import Layout from '../components/global/Layout.js';
import { HeroMedia, Cta } from '../components/resources/Hero.js'
import Content from "../components/resources/Content.js";
import FooterCalloutBar from "../components/global/FooterCalloutBar.js";
import scrollToElement from '../components/global/ScrollToElement';
import Header from '../components/global/Header.js';

const Resources = () => {
  return (
    <>
      <Header page="resources" scrollToElement={scrollToElement} />
      <Layout
        page="resources"
        heroMedia={<HeroMedia />}
        CTA={Cta}
      >
        <Content />
        <FooterCalloutBar/>
      </Layout>
    </>
  )
}

export default Resources
