import * as React from "react";
import Social from './Social.js';
import Footer from './Footer.js';
import Hero from './Hero.js';
import { Helmet } from "react-helmet";
import { Interstitial } from "./Interstitial.js";
import SEO from './SEO.js';
import { useLocation } from '@reach/router';
import setInterstitialText from "./SetInterstitialText.js";
import scrollToElement from './ScrollToElement';


const Layout = (props) => {

  React.useEffect(() => {
    if (props.page === "home") {
      window.scrollTo(0, 1);
    }
    if ( props.page === "esp") {
      window.scrollTo(0, 1);
      // updateInterstitialTextEsp();
    }
    
    const interstitialLinks = document.querySelectorAll('.opens-interstitial');
    const interstitial = document.querySelector('.interstitial-container');
    const body = document.querySelector('body');
    interstitialLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        let url = e.target.href;
        const interstitialType = e.target.getAttribute('data-interstitial-type') || 'aidsvu';
        setInterstitialText(interstitialType);
        interstitial.classList.remove('interstitial-hidden');
        body.classList.add('hide-scroll');
        let interstitialContinue = interstitial.querySelector('.interstitial-link.continue');
        interstitialContinue.href = url;
      })
    });
  }, [])

  
  const publicUrl = useLocation().href;
  const CTA = props.CTA;
  return (
      <div id="smooth-wrapper">
        <div id="smooth-content">
    <div className={props.page}>
      <Helmet
        title={SEO[props.page].title}>
        <meta
          name="description"
          content={SEO[props.page].description}
        />
        <meta 
          property="og:image" 
          content={publicUrl + 'images/global/SPL_ShareImage.jpg'}
        />
      </Helmet>
      <Hero
        heroMedia={props.heroMedia}
        heroCopy={props.heroCopy}
        CTA={ ( props.page==="home" || props.page==="esp" ) ? props.CTA : <CTA scrollToElement={scrollToElement}  /> } 
        page={props.page}
      />
      <main>
        {props.children}
      </main>
      {
        !props.noSocial &&
        <Social hasBar={props.socialHasBar} page={props.page} />
      } 
      <Footer page={ props.page }/>
      <Interstitial esp={ props.page === "esp" } />
    </div>
        </div>
      </div>
  )

}

export default Layout
