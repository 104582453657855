import * as React from "react"
import Bar from "../global/Bar.js"

const Social = (props) => {

  const backToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }

  return (
    <div className={`${props.page === "resources" ? "" : "d-none"} container-fluid bkg-light-gray`}>
      {props.hasBar &&
        <div className="container px-0">
          <div className="row">
            <div className="col">
              <Bar classes="orange" />
            </div>
          </div>
        </div>
      }
      <div className="container social px-0">
        <div className="row">
          <div className="col">
            <h2>
              Follow Us On Social
            </h2>
            <iframe
              allowFullScreen
              id="wallsio-iframe"
              src="https://my.walls.io/e4za3?nobackground=1&colorscheme=light&theme=fluid&show_header=0&lang=en_US"
              style={{ border: "0", height: "580px", width: "100%" }}
              loading="lazy"
              title="HIVTestNow Social Wall">
            </iframe>
            <button
              className="back-to-top"
              onClick={() => backToTop()}
            >
              <img className="icon" src='/images/global/triangle-blue.svg' alt="back-to-top icon" />
              <p className="copy" >
                BACK TO TOP
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Social
