import * as React from 'react';
import Articles from './Articles.js';
import VideoModal from '../global/VideoModal.js';

const Content = () => {
  return (
    <section id="nav-anchor-resources" className="container-fluid resources bkg-white">
      <Articles />
      <div className="container px-0">
        <div className="row">
          <div className="col">
            <p class="talk-to-a-healthcare-professional">
              Talk to a healthcare professional about what's right for&nbsp;you.
            </p>
            <h3 className="video">
              Press Play on Moving Forward Video
            </h3>
            <button type="button" className="open-video-modal" data-bs-toggle="modal" data-bs-target="#video-modal">
              <img src="/images/resources/4.0_moving_forward_video_thumbnail.png" alt="Click to start the Press Play moving on video" />
            </button>
          </div>
        </div>
      </div>
      <VideoModal />
    </section>
  )

}

export default Content;