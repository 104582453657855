import * as React from 'react';
import $ from 'jquery';

class VideoModal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

    const videoModal = document.getElementById('video-modal');
    const videoHome = $('#video-home');

    if (this.props.autoplay !== "false") {
      videoModal.addEventListener('shown.bs.modal', function () {
        $('#hero-video').trigger('play');
        if (videoHome) videoHome.trigger('pause');
      })
    }
    else {
      videoModal.addEventListener('shown.bs.modal', function () {
        if (videoHome) videoHome.trigger('pause');
      })
    }

    videoModal.addEventListener('hide.bs.modal', function () {
      $('#hero-video').trigger('pause');
      if (videoHome) videoHome.trigger('play');
    })
  }

  render() {
    return (
      <section className="container px-0 modal-video bkg-white">
        <div className="modal fade" id="video-modal" tabIndex="-1" aria-labelledby="video-modalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button className="close" data-bs-dismiss="modal"></button>
              <div className="modal-body">
                autplay is
                {this.props.autoplay}
                <video id="hero-video" controls poster="/images/global/video-poster.png ">
                  <source src="../video/Gilead-HIV-Franchise-Press-Play-Video-60.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

}

export default VideoModal;